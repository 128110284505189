<template>
  <div class="ls-page">
    <div class="ps-section__header ls-page-header">
      <h1 class="container ls-page-header-title">About Us</h1>
      <h3 class="container ls-page-header-subtitle">
        Here is more information on the products and the team behind it
      </h3>
    </div>
    <div class="ps-section__content ls-page-content">
      <div class="row">
        <div class="col-12 col-xs-2 col-md-2" />
        <div class="col-12 col-xs-8 col-md-8 ls-full-col-image">
          <h2>Who is Pahukama?</h2>
          <p>
            Pahukama is a multi-category e-commerce that exists to offer
            Diasporas a multi-vendor marketplace to send remittances differently
            and directly through purchases of products and services or make bill
            payments, at one go, ensuring a seamless transition from one store
            to another, saving time and logistical complexities.
          </p>
        </div>
        <div class="col-12 col-xs-2 col-md-2" />

        <div class="col-12 col-xs-2 col-md-2" />
        <div class="col-12 col-xs-8 col-md-8 ls-full-col-image">
          <h2>Our Vision</h2>
          <p>
            To be the most sought-after e-commerce platform in Africa
          </p>
        </div>
        <div class="col-12 col-xs-2 col-md-2" />
        <div class="col-12 col-xs-2 col-md-2" />
        <div class="col-12 col-xs-8 col-md-8 ls-full-col-image">
          <h2>Our Mission</h2>
          <p>
            To build an easily accessible, real-time, and affordable e-commerce
            marketplace for all types of goods and services.
          </p>
          <div class="row">
            <div class="col-xs-12 col-6">
              <h3>Through;</h3>
              <p>
                A credible, easy to use, and trustworthy global e-commerce
                platform with solid back-end infrastructure and an almost
                infinite category of goods and services
              </p>
            </div>
            <div class="col-xs-12 col-6">
              <h3>Being;</h3>
              <div>
                <ul>
                  <li>
                    High customer experience, strong communication skills,
                    passion to serve Zimbabwe home and abroad
                  </li>
                  <li>
                    Huge design investment in product and service search,
                    organization, and categorization
                  </li>
                  <li>
                    Technological and business innovation matched with
                    logistical and operational excellence
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-xs-2 col-md-2" />
        <div class="col-12 col-xs-2 col-md-2" />
        <div class="col-12 col-xs-8 col-md-8 ls-full-col-image">
          <h2>Our Values</h2>
          <div>
            <ul>
              <li>Reliability</li>
              <li>Integrity</li>
              <li>Professionalism</li>
              <li>Accountability</li>
              <li>Responsiveness</li>
            </ul>
            <div class="dropdown">
              <button
                class="btn btn-secondary dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Dropdown button
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" href="#">Action</a>
                <a class="dropdown-item" href="#">Another action</a>
                <a class="dropdown-item" href="#">Something else here</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-xs-2 col-md-2" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
};
</script>
